import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div><h1>PALM COAST GAMES</h1></div>
        <div>Games and apps built in Palm Coast, Florida</div>
        <div>by Clint Willard</div>
      </header>
    </div>
  );
}

export default App;
